<i18n>
ru:
  pagination: '{index} из {total}'
  pic: 'Картинка №{num}'
ua:
  pagination: '{index} из {total}'
  pic: 'Картинка №{num}'
us:
  pagination: '{index} of {total}'
  pic: 'Picture #{num}'
</i18n>

<template>
  <div
    class="v-gallery"
    :class="[inPopup ? 'v-gallery__in-popup' : 'v-gallery__on-page']"
  >
    <div
      v-if="isSmall && inPopup"
      class="v-gallery-mobile-block"
    >
      <icon-old-general-chevron-right
        class="v-pointer v-gallery-mobile-block--back v-mr-sm"
        @click="async () => await popupStore.closePopup()"
      />
      <span
        v-html="
          translate('gallery.pagination', {
            index: activeIndex + 1,
            total: imagesCorrected.length
          })
        "
      />
    </div>
    <arora-swiper-slider
      enable-navigation
      loop
      :autoplay-delay="autoPlayDelay"
      :initial-slide="activeNumber"
      :items="imagesCorrected"
      :max-items="itemsNumber"
      :pagination-type="showThumbs ? 'fraction' : 'none'"
      :show-part-of-next-slide="false"
      :thumbs-slides-items="thumbsNumber"
      v-model:active-index="activeIndex"
    >
      <template #indexedItem="{ item, index }">
        <div
          class="v-pointer v-d-flex v-justify-content-center v-align-items-center"
          @click="openImage(index)"
        >
          <arora-nuxt-image
            disable-lazy
            disable-resize
            :alt="translate('gallery.pic', { num: index + 1 })"
            :image="item.Image"
          />
        </div>
      </template>
      <template
        v-if="showThumbs"
        #thumbsIndexedItem="{ item, index }"
      >
        <arora-nuxt-image
          disable-lazy
          :alt="translate('gallery.pic', { num: index + 1 })"
          :image="item.Image"
        />
      </template>
    </arora-swiper-slider>
  </div>
</template>

<script setup lang="ts">
import type { Gallery, ImageInfoWithID } from '~types/props'

import { useWindowSize } from '@arora/common'

const {
  activeNumber = 0,
  autoPlayDelay,
  images,
  inPopup = false,
  itemsNumber = 1,
  showThumbs = true
} = defineProps<
  Gallery & {
    itemsNumber?: number
    inPopup?: boolean
  }
>()

const popupStore = usePopupStore()

const { translate } = useI18nSanitized()
const { isSmall } = useWindowSize()

function openImage(index: number): void {
  if (inPopup) {
    const image = images[index]
    navigateTo(`${image?.Domain ?? ''}${image?.Path}`, {
      external: true,
      open: {
        target: '_blank'
      }
    })
  } else {
    popupStore.openPopup({
      popupClosable: true,
      popupName: 'galleryPopup',
      popupProperties: new Map<string, unknown>([
        ['activeNumber', index],
        ['autoPlayDelay', autoPlayDelay],
        ['images', images],
        ['showThumbs', showThumbs]
      ])
    })
  }
}
const activeIndex = ref<number>(activeNumber ?? 0)

const thumbsNumber = computed<number>(() => {
  if (showThumbs) {
    return inPopup ? 5 : 4
  }
  return 0
})

const imagesCorrected = computed<ImageInfoWithID[]>(() =>
  images
    ? images.map((img) => {
        return { ID: useId(), Image: img }
      })
    : []
)
</script>

<style lang="scss">
@use 'assets/variables';
@use 'assets/mixins';

.v-gallery {
  @include mixins.swiper-button-default-big;

  &__in-popup {
    --swiper-navigation-sides-offset: -60px;
    --swiper-pagination-bottom: 20px;

    @include mixins.lg {
      max-width: 650px;
    }

    .v-swiper {
      max-width: 750px;
    }

    .v-swiper .swiper-wrapper {
      margin-bottom: 10px;
    }

    .swiper-pagination {
      font-size: 1.715rem;
      color: variables.$PopupBackground;
    }

    .v-swiper-thumbs {
      scale: 1.1;
    }
  }
  &__on-page {
    --swiper-navigation-sides-offset: 20px;
  }

  .swiper-slide {
    .v-img-fluid {
      border-radius: variables.$BorderRadius;

      width: 100%;
      height: auto;
    }
  }

  .v-gallery-mobile-block {
    top: 60px;
    left: 30px;
    position: fixed;
    height: fit-content;
    width: fit-content;
    color: variables.$PopupOverlayColor;
    font-size: 1.25rem;
    font-weight: 600;
    z-index: 1060;

    &--back {
      transform: rotate(-180deg);
      width: 24px;
      height: 24px;
    }
  }
}
</style>
